<template>
    <div class="modal profile_modal share_playbook" :class="{active : modelValue}" v-if="modelValue" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0 pb-4">Playbook Analytics</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <ul class="analytics">
                    <li>
                        <div class="analytics_card">
                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                <div class="inner_circle">
                                    <div class="score" style="transform: scale(0.8);">{{ analyics.members }}</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2F7FED" :stroke-dashoffset="202 - (analyics.members_rate * 2.02)" stroke-dasharray="202"/>
                                </svg>
                            </div>
                            <h4>Members</h4>
                        </div>
                    </li>
                    <li>
                        <div class="analytics_card">
                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                <div class="inner_circle">
                                    <div class="score" style="transform: scale(0.8);">{{analyics.completed }}</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#F2A31D" :stroke-dashoffset="202 - (analyics.completion_rate * 2.02)" stroke-dasharray="202"/>
                                </svg>
                            </div>
                            <h4>Completed</h4>
                        </div>
                    </li>
                    <li>
                        <div class="analytics_card">
                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                <div class="inner_circle">
                                    <div class="score" style="transform: scale(0.8);">{{ analyics.started }}</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#1df2ce" :stroke-dashoffset="202 - (analyics.started_rate * 2.02)" stroke-dasharray="202"/>
                                </svg>
                            </div>
                            <h4>Started</h4>
                        </div>
                    </li>
                    <li>
                        <div class="analytics_card">
                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                <div class="inner_circle">
                                    <div class="score" style="transform: scale(0.8);">{{ analyics.questions }}</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#ff7f7f" :stroke-dashoffset="202" stroke-dasharray="202"/>
                                </svg>
                            </div>
                            <h4>Questions</h4>
                        </div>
                    </li>
                    <li>
                        <div class="analytics_card">
                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                <div class="inner_circle">
                                    <div class="score" style="transform: scale(0.8);">{{ analyics.non_started }}</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#9a1df2" :stroke-dashoffset="202 - (analyics.non_started_rate * 2.02)" stroke-dasharray="202"/>
                                </svg>
                            </div>
                            <h4>Non-starter</h4>
                        </div>
                    </li>
                    <li>
                        <div class="analytics_card">
                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                <div class="inner_circle">
                                    <div class="score" style="transform: scale(0.8);">{{ analyics.visits }}</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#5a5a5a" :stroke-dashoffset="202" stroke-dasharray="202"/>
                                </svg>
                            </div>
                            <h4>Visits</h4>
                        </div>
                    </li>
                </ul>
                <ul class="tab_row border-bottom">
                    <li :class="analyricsTab === 'user' ? 'active' : ''" @click="analyricsTab = 'user'">Users</li>
                    <li :class="analyricsTab === 'question' ? 'active' : ''" @click="analyricsTab = 'question'">Questions</li>
                </ul>
                <div v-show="analyricsTab === 'user'">
                    <analytics-user :playbook="playbook" />
                </div>
                <div v-show="analyricsTab === 'question'">
                    <analytics-question :playbook="playbook" />
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const AnalyticsUser = defineAsyncComponent(() => import('@/pages/playbook/components/AnalyticsUser'))
    const AnalyticsQuestion = defineAsyncComponent(() => import('@/pages/playbook/components/AnalyticsQuestion'))

    export default {
        name: 'Playbook Analytics',

        data () {
            return {
                analyricsTab: 'user',
                pageFilter: false,
                analyticData: {
                    members: 0,
                    completion: 0,
                    engagement: 0,
                    questions: 0,
                    nonStarter: 0,
                    visits: 0,
                    membersBar: 0,
                    engagementBar: 0,
                    questionsBar: 0,
                    nonStarterBar: 0,
                    visitsBar: 0,
                }
            }
        },

        props: {
            modelValue: Boolean,
            playbook: Object,
        },

        emit: ['update:modelValue'],

        components: {
            AnalyticsUser,
            AnalyticsQuestion
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    const params = { playbook_id: vm.playbook.id, title: 'Copy of Cover'}

                    vm.getAnalytics(params);
                }
            },

            analyics (analyics) {
                const vm = this;

                vm.resetAnalyticsData();
            }
        },

        computed: mapState({
            analyics: state => state.playbookModule.analyics,
            loader: state => state.playbookModule.playbookUserLoader,
        }),

        mounted () {
            const vm = this;

            if (Object.keys(vm.$route.query).length && vm.$route.query.action == 'answer-question') {
                vm.analyricsTab = 'question';
            }
        },

        methods: {
            ...mapActions({
                getAnalytics: 'playbookModule/getAnalytics'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            getAnalyticsRate (value, valueRate, bar = false) {
                const vm = this;

                if (value && value <= valueRate) {
                    const count = Math.round((value/valueRate) * 100);

                    if (bar) {
                        const bar = 100 - count;
                        return (bar * 202) / 100;
                    } else {
                        return count;
                    }
                } else {
                    return bar ? 202 : 0;
                }
            },

            resetAnalyticsData () {
                const vm = this;

                vm.analyticData = {
                    members: vm.getAnalyticsRate(vm.analyics.members, vm.analyics.members_rate),
                    engagement: vm.getAnalyticsRate(vm.analyics.engagement, vm.analyics.engagement_rate),
                    questions: vm.getAnalyticsRate(vm.analyics.questions, vm.analyics.questions_rate),
                    nonStarter: vm.getAnalyticsRate(vm.analyics.non_starter, vm.analyics.non_starter_rate),
                    visits: vm.getAnalyticsRate(vm.analyics.visits, vm.analyics.visits_rate),
                    membersBar: vm.getAnalyticsRate(vm.analyics.members, vm.analyics.members_rate, true),
                    engagementBar: vm.getAnalyticsRate(vm.analyics.engagement, vm.analyics.engagement_rate, true),
                    questionsBar: vm.getAnalyticsRate(vm.analyics.questions, vm.analyics.questions_rate, true),
                    nonStarterBar: vm.getAnalyticsRate(vm.analyics.non_starter, vm.analyics.non_starter_rate, true),
                    visitsBar: vm.getAnalyticsRate(vm.analyics.visits, vm.analyics.visits_rate, true),
                }
            }
        }
    }
</script>

<style>
    .analytics {
        display: flex;
        flex-wrap: wrap;
        margin: 15px -10px 0 -10px;
    }

    .analytics li {
        padding: 10px;
        width: 16.666%;
    }

    .analytics_card {
        /* border: 1px solid #eaeaea; */
        background: #fff;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    .analytics_card h4 {
        font-size: 15px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
        margin-top: 10px;
    }

    .analytics_card:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
</style>
